<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ pageTitle }} watcher</h1>
        <!--end::Title-->
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link
            :to="{ name: 'watchers.show', params: { id: watcher.id } }"
            class="btn btn-sm btn-primary"
            v-if="watcher"
          >
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template>
          <form class="default-form pt-10" @submit.prevent="">
            <LoaderOverlay
              size="big"
              color="color2"
              v-if="overlayLoader"
            />
            <div class="d-flex flex-column flex-xl-row  gap-xl-12">
              <div class="col-12 col-xl-2 mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Tipo</label>
                <!--end::Label-->
                <select class="form-select" v-model.trim="form.type">
                  <option value="CAMPAIGN">Campanha</option>
                </select>
              </div>
              <div class="col-12 col-xl-2 mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Métrica</label>
                <!--end::Label-->
                <select class="form-select" v-model.trim="form.metric">
                  <option value="SPENDING_RATE">Gasto</option>
                  <option value="CLICK">Clique</option>
                  <option value="CTR">CTR</option>
                  <option value="CPM">CPM</option>
                  <option value="IMPRESSION">Impressão</option>
                </select>
              </div>
              <div class="col-12 col-xl-2 mb-5">
              <!--begin::Label-->
                <label class="form-label fw-semibold">Regra</label>
                <!--end::Label-->
                <select class="form-select" v-model.trim="form.value">
                  <option value="EVEN">Even</option>
                </select>
              </div>
            </div>
            <div class="mt-3 text-center">
              <Button
                class="btn btn-primary"
                @click="submit"
                :loading="loader"
              >
                {{ isEditPage ? 'Salvar watcher' : 'Criar watcher' }}
              </Button>
            </div>
          </form>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import WatchersService from '@/modules/watchers/services/watchers-service'

import Button from '@/components/common/Button/Button'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Button,
    LoaderOverlay,
    MainLayout
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  data () {
    return {
      loader: false,
      overlayLoader: false,
      form: {
        type: 'CAMPAIGN',
        metric: '',
        value: 'EVEN'
      },
      watcher: null
    }
  },
  created () {
    if (this.isEditPage) {
      this.getWatcher()
    }
  },
  computed: {
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'watchers.edit'
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'watchers.create' ? 'Criar' : 'Editar'
    }
  },
  methods: {
    /**
     * Get watcher
     */
    async getWatcher () {
      this.overlayLoader = true
      try {
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.overlayLoader = false
    },
    /**
     * Submit
     */
    async submit () {
      this.overlayLoader = true
      try {
        this.form.watchable_id = this.$route.query.campaign_id
        const form = JSON.parse(JSON.stringify(this.form))

        let watcher = null
        if (!this.watcher) {
          watcher = await WatchersService.createWatcher(form)
        } else {
          watcher = await WatchersService.updateWatcher(this.watcher.id, form)
        }

        this.$router.push({ name: 'watchers.show', params: { id: watcher.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    }
  }
}
</script>
